<script setup lang="ts">
import { Intercom } from '@intercom/messenger-js-sdk';
import type { InitType } from '@intercom/messenger-js-sdk/types';
import type { Order } from '~/types';

const { order } = defineProps<{
  order: Order | null;
}>();

const intercomConfig: InitType = {
  app_id: IntercomConfig.appId,
};
if (order) {
  intercomConfig.name = `${order.customer_first_name} ${order.customer_last_name}`;
  intercomConfig.email = order.customer_email;
  intercomConfig.created_at = Math.floor(new Date(order.created_at).getTime() / 1000);
  /**
   * Custom customer attribute that needs to be set up in Intercom
   * https://www.intercom.com/help/en/articles/179-create-and-track-custom-data-attributes-cdas
   **/
  intercomConfig['Order Identifier'] = order.identifier;
}
Intercom(intercomConfig);
</script>
